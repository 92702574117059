<template>
  <div>
    <b-container class="base-container-x about-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div class="banner-inner about-us d-none">
        <div v-if="loading == false" class="slideshow_caption">
          <div class="slideshow_inner_caption" style="position: absolute;">
            <div class="slideshow_align_caption">
              <h1 class="section-header-title">{{ $t('Feed Back') }}</h1>
              <h3 class="article-content">{{ $t('Welcome to WYA International Community!') }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading == true" class="col-12 mt-4">
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
          <br/>
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading == false" class="col-12 mb-5">
        <div class="row mt-5 justify-content-center pb-5" style="height:1000px;">
            <h1 class="mb-3">{{ $t('Feedback')}}</h1>
            <b-embed type="iframe" :src="iframeSrc" aspect="16by9" allowfullscreen>
          </b-embed>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'About',
  title: ' | About Us',
  data () {
    return {
      loading: true,
      iframeSrc: 'https://docs.google.com/forms/d/e/1FAIpQLSeK6R_6H575o7H8ySjHt-y-PyjH4YgRS_-3nSArea2RkKvjfg/viewform'
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style scoped>
  .embed-responsive {
    height: 100%;
  }
</style>
